// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-disclaimer{
    background-color: #6f7a8f;
    min-height: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px;
    text-align: center;
    font-size: calc(3px + 1vmin);
    font-weight: bold;
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,kBAAkB;IAClB,4BAA4B;IAC5B,iBAAiB;IACjB,YAAY;EACd","sourcesContent":[".footer-disclaimer{\n    background-color: #6f7a8f;\n    min-height: 10px;\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    padding: 8px;\n    text-align: center;\n    font-size: calc(3px + 1vmin);\n    font-weight: bold;\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
