// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tabs {
    max-width: 32rem;
    margin: 2rem 0;
    overflow: hidden;
  }
  
  #tabs menu {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 0.25rem;
  }
  
  #tabs button {
    font: inherit;
    font-size: 0.85rem;
    background-color: #282f33;
    border: none;
    border-bottom-color: #48d9f3;
    color: #e0eff1;
    border-radius: 4px 4px 0 0;
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease-out;
  }
  
  #tabs button:hover,
  #tabs button.active {
    background-color: #48d9f3;
    color: #273133;
  }
  
  #tab-content {
    background-color: #2d3942;
    border-radius: 0 4px 4px 4px;
    padding: 1rem;
  }
  
  #tab-content li {
    margin: 0.75rem 0;
  }`, "",{"version":3,"sources":["webpack://./src/Tabs.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,SAAS;IACT,UAAU;IACV,aAAa;IACb,YAAY;EACd;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,4BAA4B;IAC5B,cAAc;IACd,0BAA0B;IAC1B,qBAAqB;IACrB,eAAe;IACf,6BAA6B;EAC/B;;EAEA;;IAEE,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,4BAA4B;IAC5B,aAAa;EACf;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":["#tabs {\n    max-width: 32rem;\n    margin: 2rem 0;\n    overflow: hidden;\n  }\n  \n  #tabs menu {\n    margin: 0;\n    padding: 0;\n    display: flex;\n    gap: 0.25rem;\n  }\n  \n  #tabs button {\n    font: inherit;\n    font-size: 0.85rem;\n    background-color: #282f33;\n    border: none;\n    border-bottom-color: #48d9f3;\n    color: #e0eff1;\n    border-radius: 4px 4px 0 0;\n    padding: 0.75rem 1rem;\n    cursor: pointer;\n    transition: all 0.2s ease-out;\n  }\n  \n  #tabs button:hover,\n  #tabs button.active {\n    background-color: #48d9f3;\n    color: #273133;\n  }\n  \n  #tab-content {\n    background-color: #2d3942;\n    border-radius: 0 4px 4px 4px;\n    padding: 1rem;\n  }\n  \n  #tab-content li {\n    margin: 0.75rem 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
